import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@beewise/button';
import Select from '@beewise/select';
import Input from '@beewise/input';
import { clearCreatedBhome, fetchCreateNewBhome } from '../actions';

const BHOME_CAPACITY_OPTIONS = [
    { text: '696 (Bhome 24)', value: 696 },
    { text: '720 (Bhome 24)', value: 720 },
];

const BhomeCreationTab = ({ isSuperViewer }) => {
    const [slotsAmount, setSlotsAmount] = useState(BHOME_CAPACITY_OPTIONS[0].value);
    const [id, setId] = useState('');

    const createdBhome = useSelector(state => state.dashboard.createdBhome);
    const dispatch = useDispatch();

    const handleSaveBhome = useCallback(() => {
        dispatch(fetchCreateNewBhome(slotsAmount, Number(id)));
    }, [dispatch, id, slotsAmount]);

    const handleClearBhome = useCallback(() => {
        if (createdBhome) {
            dispatch(clearCreatedBhome());
        }
    }, [createdBhome, dispatch]);

    const handleChangeSlotAmount = useCallback((e, { value }) => setSlotsAmount(value), []);
    const handleChangeId = useCallback(e => setId(e.target.value), []);

    return (
        <div className="bhome-creation">
            {createdBhome ? (
                <div>
                    <div>Bhome with ID: {createdBhome.id} was successfully created</div>
                    <Button className="clear-bhome" onClick={handleClearBhome}>
                        Create one more beehome
                    </Button>
                </div>
            ) : (
                <>
                    <h2>Create New Beehome</h2>
                    <h4>New Beehome will be assigned to Beewise Company (id: 1)</h4>
                    <div className="bhome-creation-form">
                        <div className="bhome-creation-form-group">
                            <Input type="number" value={id} onChange={handleChangeId} label="ID (optional)" />
                        </div>
                        <div className="bhome-creation-label">Slots Amount</div>
                        <Select
                            defaultValue={slotsAmount}
                            options={BHOME_CAPACITY_OPTIONS}
                            onChange={handleChangeSlotAmount}
                            placeholder="Select beehome slots amount"
                        />
                        {!isSuperViewer && (
                            <Button className="btn-primary" onClick={handleSaveBhome}>
                                Create new Beehome
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

BhomeCreationTab.propTypes = {
    isSuperViewer: PropTypes.bool,
};

export default BhomeCreationTab;
