import constants from '@beewise/constants';

/**
 * Creates a command to move a frame.
 * @param {Object} param0 - Object containing frame, place, shake, and untuck_direction.
 * @param {Object} param0.frame - The frame to be moved.
 * @param {Object} param0.place - The place to move the frame to.
 * @param {boolean} param0.shake - Whether to shake the frame or not.
 * @param {number} param0.untuckDirection - The direction to untuck the frame.
 * @returns {Object} An object representing the move frame command.
 */
const createMoveFrameCommand = ({ frame, place, shake, untuckDirection }) => ({
  command: constants.COMMANDS.MOVE_FRAME,
  params: {
    frame,
    place,
    shake,
    untuck_direction: untuckDirection
  }
});

/**
 * Creates a command to close the hive.
 * @param {Object} param0 - Object containing frame.
 * @param {Object} param0.frame - The frame involved in the close hive action.
 * @returns {Object} An object representing the close hive command.
 */
const createCloseHiveCommand = ({ frame }) => ({
  command: constants.COMMANDS.CLOSE_HIVE,
  params: { tuck: { direction: 1, frame } }
});

/**
 * Retrieves data for a specific frame.
 * @param {Object} param0 - Object containing frame, station, and positionX.
 * @param {Object} param0.frame - The frame object.
 * @param {string} param0.station - The station where the frame is located.
 * @param {number} param0.positionX - The X position of the frame.
 * @returns {Object} An object containing frame data.
 */
const getFrameData = ({ frame, station, positionX }) => ({
  frame_type: frame.type,
  id: frame.rfid,
  place: {
    station: station ?? frame.place.station,
    position: { x: positionX }
  }
});

/**
 * Calculates and returns the new positions of frames in the hive upon contraction.
 * @param {Object} param0 - Object containing the necessary parameters for calculation.
 * @param {Array} param0.movedFrames - The array of frames that are being moved in the hive.
 * @param {number} param0.combWidth - The width of the comb frame.
 * @returns {Array} A new array representing the updated state of the hive.
 */
const calculateNewFramesPositionOnContractHive = ({ movedFrames, combWidth }) => {
  const newMovedFrames = structuredClone(movedFrames);

  const startingPositionX = Math.min(...movedFrames.map(({ place }) => place.position.x));

  newMovedFrames.forEach((frame, index) => {
    if (!index) {
      frame.place.position.x = startingPositionX;
      return;
    }

    if (index === newMovedFrames.length - 1) {
      return;
    }

    frame.place.position.x = startingPositionX + combWidth * index;
  });

  return newMovedFrames;
};

/**
 * Generates a sequence of actions to contract the hive and hive layout with new coordinates.
 * @param {Object} param0 - Object containing movedFrames, settings, stations, and currentPartitionIndex.
 * @param {Array} param0.movedFrames - Array of frames that have been moved.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {number} param0.currentPartitionIndex - Index of the partition frame.
 * @returns {Object} An object representing the contract hive command with its sequences and hive layout with new coordinates.
 */
export const getContractHiveActionWithLayout = ({
  movedFrames,
  settings,
  stations,
  currentPartitionIndex
}) => {
  const contractHiveSequences = [];
  const partitionFrame = movedFrames[currentPartitionIndex];
  const defaultFrameWidth = constants.DEFAULT_FRAME_WIDTH.COMB_FRAME ?? 0;
  const untuckDistanceX = settings?.sequences?.untuck?.x_untuck_distance ?? 0;
  const untuckDirection = -1;
  const combWidth = settings?.physical_measurements?.comb_width ?? defaultFrameWidth;

  const preparedMovedFrames = movedFrames
    .toSpliced(currentPartitionIndex, movedFrames.length + 1)
    .sort(({ place: a }, { place: b }) => a.position.x - b.position.x);

  preparedMovedFrames.forEach((frame, index) => {
    if (frame.initialFrameIndex === index) {
      return;
    }

    const initialPartitionPositionFrameX = partitionFrame.place.position.x;

    const moveOutCommand = createMoveFrameCommand({
      frame: getFrameData({ frame, positionX: frame.place.position.x }),
      place: {
        station: constants.STATIONS.SYSTEM_SLOT,
        position: stations?.system_slot?.x
      },
      shake: true,
      untuckDirection
    });

    const partitionFrameNewPositionX =
      initialPartitionPositionFrameX + untuckDirection * untuckDistanceX;
    const closeHiveCommand = createCloseHiveCommand({
      frame: getFrameData({
        frame: partitionFrame,
        positionX: partitionFrameNewPositionX
      })
    });

    partitionFrame.place.position.x += combWidth;

    const movedFrameNewPosition = initialPartitionPositionFrameX;
    const moveBackCommand = createMoveFrameCommand({
      frame: getFrameData({
        frame,
        station: constants.STATIONS.SYSTEM_SLOT,
        positionX: stations?.system_slot?.x
      }),
      place: {
        station: frame.place.station,
        position: { x: movedFrameNewPosition }
      },
      shake: false,
      untuckDirection: 0
    });

    frame.place.position.x = movedFrameNewPosition;

    contractHiveSequences.push(moveOutCommand, closeHiveCommand, moveBackCommand);
  });

  return {
    contractHiveAction: {
      command: constants.COMMANDS.CONTRACT_HIVE,
      params: { sequences: contractHiveSequences }
    },
    newHiveLayout: calculateNewFramesPositionOnContractHive({
      movedFrames,
      combWidth
    })
  };
};
