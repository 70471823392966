import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_RANCHES, FETCH_CREATE_RANCH, FETCH_DELETE_RANCH, FETCH_UPDATE_RANCH } from '../actionTypes';

const initialState = {
    ranches: [],
    isFetched: false,
};

const ranches = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RANCHES.success:
            const ranches = action.data.ranches.map(
                ranch =>
                    ({
                        ...ranch,
                        bhomes: ranch.bhomes?.map(bhome => bhome.id),
                    }) ?? []
            );
            return {
                ...state,
                ranches,
                isFetched: true,
            };
        case FETCH_CREATE_RANCH.success:
            return {
                ...state,
                ranches: [action.data.ranch, ...state.ranches],
            };
        case FETCH_DELETE_RANCH.success:
            return {
                ...state,
                ranches: state.ranches.filter(item => item.id !== action.data.id),
            };

        case FETCH_UPDATE_RANCH.success:
            return {
                ...state,
                ranches: state.ranches.map(item => {
                    if (item.id === action.data.id) {
                        return {
                            ...item,
                            ...action.data,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default ranches;
