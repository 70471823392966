import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { arrayOfObjectsShallowEqual, loading, usePermission } from '@beewise/react-utils';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { BEEKEEPERS_APP_UI } from 'config';
import constants from 'appConstants';
import useSuperViewer from 'utils/useSuperViewer';
import { fetchAllSettings } from 'components/views/Settings/actions';
import { FETCH_ALL_SETTINGS } from 'components/views/Settings/actionTypes';
import {
    fetchAdminCompanyUpdate,
    fetchAllBhomes,
    fetchAllCompanies,
    fetchSignUp,
    fetchAdminCompanyUpdateByBhomeId,
    fetchReassignBhome,
    fetchUserRoles,
    fetchCompanyTypes,
    fetchDeleteCompaniesAndContent,
    fetchDeleteBhomesAndCloudData,
    fetchHivesCsvReportByType,
} from './actions';
import {
    UPDATE_ADMIN_COMPANY_BY_BHOME_ID,
    UPDATE_ADMIN_COMPANY,
    FETCH_REASSIGN_BHOME,
    FETCH_DELETE_BHOMES_AND_CLOUD_DATA,
    FETCH_POPULATED_HIVES_CSV_REPORT,
} from './actionTypes';
import UserTab from './components/UserTab';
import BhomeCreationTab from './components/BhomeCreationTab';
import BhomeReassign from './components/BhomeReassign';
import ClearUsersStorage from './components/ClearUsersStorage';
import BhomeSoftwareUpdate from './components/BhomeSoftwareUpdate';
import BulkBhomeSettingsUpdate from './components/BulkBhomeSettingsUpdate';
import BhomeConfig from './components/BhomeConfig/BhomeConfig';
import BhomeRevertConfig from './components/BhomeRevertConfig';
import BhomeSoftwareBundle from './components/BhomeSoftwareBundle';
import UserPermissionsTab from './components/UserPermissions';

const initialState = {
    email: '',
    companyName: '',
    phone: '',
    role: '',
    companyTypes: [],
    permissions: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                [action.name]: action.value,
            };
        case 'CLEAR':
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

const Dashboard = () => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [invitationUrl, setInvitationUrl] = useState(null);
    const isBhomeCreationAllowed = usePermission('createBhome', 'admin');
    const isChangeRolesAllowed = usePermission('changeRoles', 'admin');

    const [state, stateDispatch] = useReducer(reducer, initialState);

    const dispatch = useDispatch();

    const companies = useSelector(state => state.dashboard.companies, arrayOfObjectsShallowEqual);
    const signupError = useSelector(state => state.dashboard.signupError);
    const bhomes = useSelector(state => state.dashboard.bhomes, arrayOfObjectsShallowEqual);
    const roles = useSelector(state => state.dashboard.roles, arrayOfObjectsShallowEqual);
    const companyTypes = useSelector(state => state.dashboard.companyTypes, arrayOfObjectsShallowEqual);
    const settings = useSelector(state => state.settings.settings, arrayOfObjectsShallowEqual);
    const isSuperViewer = useSuperViewer();

    useEffect(() => {
        dispatch(fetchAllCompanies());
        dispatch(fetchAllBhomes());
        dispatch(fetchUserRoles());
        dispatch(fetchCompanyTypes());
        dispatch(fetchAllSettings());
    }, [dispatch]);

    const handleRedirect = useCallback(
        link => data => window.open(`${BEEKEEPERS_APP_UI}${link}?admin_token=${data.token}`, '_blank'),
        []
    );

    useEffect(() => {
        if (selectedCompany) {
            dispatch(fetchAdminCompanyUpdate(selectedCompany, handleRedirect('/')));
        }
    }, [selectedCompany, dispatch, companies, handleRedirect]);

    const createUser = useCallback(() => {
        if (
            (roles.find(
                role =>
                    state.role === role.id &&
                    (role.name === constants.ROLES.ADMIN ||
                        role.name === constants.ROLES.READ ||
                        role.name === constants.ROLES.FARM_MANAGER ||
                        role.name === constants.ROLES.INSTALLER)
            ) &&
                Object.values(omit(state, 'phone')).every(item => !!item)) ||
            (roles.find(
                role =>
                    state.role === role.id &&
                    (role.name === constants.ROLES.SUPER_ADMIN ||
                        role.name === constants.ROLES.TECHNICIAN ||
                        role.name === constants.ROLES.SUPER_VIEWER)
            ) &&
                state.email)
        ) {
            const stateToSend = { ...state };
            if (typeof state.companyName === 'number') {
                stateToSend.companyName = companies.find(item => item.id === state.companyName)?.name;
            }
            stateToSend.permissions = state?.permissions?.data;
            dispatch(
                fetchSignUp(stateToSend, ({ user }) => {
                    setInvitationUrl(user.invitationUrl);
                })
            );
        }
    }, [roles, state, dispatch, companies]);

    const handleSelectBhome = useCallback(
        (e, { value }) => {
            if (value) {
                dispatch(fetchAdminCompanyUpdateByBhomeId(value, handleRedirect(`/bhome/${value}`)));
            }
        },
        [dispatch, handleRedirect]
    );

    const handleCompaniesDelete = useCallback(
        ids => {
            dispatch(
                fetchDeleteCompaniesAndContent(ids, () => {
                    dispatch(fetchAllCompanies());
                    dispatch(fetchAllBhomes());
                })
            );
        },
        [dispatch]
    );

    const handleBhomesDelete = useCallback(
        ids => {
            dispatch(
                fetchDeleteBhomesAndCloudData(ids, () => {
                    dispatch(fetchAllBhomes());
                })
            );
        },
        [dispatch]
    );

    const handleBhomeCompanyChange = useCallback(
        data => {
            if (!isSuperViewer) {
                dispatch(
                    fetchReassignBhome(data, {
                        toastText: <div className="scan-toast">Success!</div>,
                        toastOptions: {
                            position: 'top-center',
                            className: 'beewise-toast action-toast toast-success',
                        },
                    })
                );
            }
        },
        [dispatch, isSuperViewer]
    );

    const onInputChange = useCallback(
        (name, value) => {
            if (name === 'role') {
                const roleName = roles.find(role => role.id === value)?.name;
                const permission = settings?.find(item => item.type === `${roleName}_permissions`);
                if (permission) {
                    stateDispatch({
                        type: 'CHANGE',
                        name: 'permissions',
                        value: permission,
                    });
                }
            } else if (name === 'permissions') {
                stateDispatch({
                    type: 'CHANGE',
                    name,
                    value: {
                        ...state.permissions,
                        data: value,
                    },
                });

                return;
            }

            stateDispatch({
                type: 'CHANGE',
                name,
                value,
            });
        },
        [roles, settings, state.permissions]
    );

    const createAnotherUser = useCallback(() => {
        stateDispatch({
            type: 'CLEAR',
        });
        setInvitationUrl(null);
    }, []);

    const handleSetCompany = useCallback((e, { value }) => {
        setSelectedCompany(value);
    }, []);

    const handleFetchCsvReportClick = useCallback(type => () => dispatch(fetchHivesCsvReportByType(type)), [dispatch]);

    const panes = useMemo(
        () => [
            {
                menuItem: 'Login/User Creation',
                render: () => (
                    <Tab.Pane>
                        <UserTab
                            companies={companies}
                            bhomes={bhomes}
                            createAnotherUser={createAnotherUser}
                            createUser={createUser}
                            invitationUrl={invitationUrl}
                            onInputChange={onInputChange}
                            handleSetCompany={handleSetCompany}
                            signupError={signupError}
                            state={state}
                            stateDispatch={stateDispatch}
                            handleSelectBhome={handleSelectBhome}
                            roles={roles}
                            companyTypes={companyTypes}
                            isSuperViewer={isSuperViewer}
                            handleCompaniesDelete={handleCompaniesDelete}
                            handleBhomesDelete={handleBhomesDelete}
                            handleFetchPopulatedCsvReport={handleFetchCsvReportClick('populated')}
                            handleFetchDeadoutsCsvReport={handleFetchCsvReportClick('deadout-repopulated')}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Bulk Bhome Settings Update',
                render: () => (
                    <Tab.Pane>
                        <BulkBhomeSettingsUpdate bhomes={bhomes} isSuperViewer={isSuperViewer} />
                    </Tab.Pane>
                ),
            },
            ...(isBhomeCreationAllowed
                ? [
                      {
                          menuItem: 'Create New Bhome',
                          render: () => (
                              <Tab.Pane>
                                  <BhomeCreationTab isSuperViewer={isSuperViewer} />
                              </Tab.Pane>
                          ),
                      },
                  ]
                : []),
            {
                menuItem: 'Reassign Bhome to other Company',
                render: () => (
                    <Tab.Pane>
                        <BhomeReassign
                            bhomes={bhomes}
                            companies={companies}
                            handleBhomeCompanyChange={handleBhomeCompanyChange}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Bhome Software Update',
                render: () => (
                    <Tab.Pane>
                        <BhomeSoftwareUpdate bhomes={bhomes} isSuperViewer={isSuperViewer} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Bhome Software Bundle',
                render: () => (
                    <Tab.Pane>
                        <BhomeSoftwareBundle isSuperViewer={isSuperViewer} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Bhome Config',
                render: () => (
                    <Tab.Pane>
                        <BhomeConfig bhomes={bhomes} isSuperViewer={isSuperViewer} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Revert Bhome Config',
                render: () => (
                    <Tab.Pane>
                        <BhomeRevertConfig bhomes={bhomes} isSuperViewer={isSuperViewer} />
                    </Tab.Pane>
                ),
            },
            ...(isChangeRolesAllowed
                ? [
                      {
                          menuItem: 'Change User Permissions',
                          render: () => (
                              <Tab.Pane>
                                  <UserPermissionsTab />
                              </Tab.Pane>
                          ),
                      },
                  ]
                : []),
            {
                menuItem: 'Clear Users Storage',
                render: () => (
                    <Tab.Pane>
                        <ClearUsersStorage />
                    </Tab.Pane>
                ),
            },
        ],
        [
            bhomes,
            companies,
            companyTypes,
            createAnotherUser,
            createUser,
            handleBhomeCompanyChange,
            handleBhomesDelete,
            handleCompaniesDelete,
            handleFetchCsvReportClick,
            handleSelectBhome,
            handleSetCompany,
            invitationUrl,
            isBhomeCreationAllowed,
            isChangeRolesAllowed,
            isSuperViewer,
            onInputChange,
            roles,
            signupError,
            state,
        ]
    );

    /* eslint-disable-next-line import/no-named-as-default */
    return (
        <div className="dashboard">
            <Tab panes={panes} />
        </div>
    );
};

export default loading([
    UPDATE_ADMIN_COMPANY_BY_BHOME_ID.default,
    UPDATE_ADMIN_COMPANY.default,
    FETCH_REASSIGN_BHOME.default,
    FETCH_ALL_SETTINGS.default,
    FETCH_DELETE_BHOMES_AND_CLOUD_DATA.default,
    FETCH_POPULATED_HIVES_CSV_REPORT.default,
])(Dashboard);
