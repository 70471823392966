/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '@beewise/input';
import Button from '@beewise/button';
import analytics from 'utils/analytics';
import { getUrlParam } from '@beewise/utils';
import { setPasswordAndUsername as setPasswordAndUsernameAction } from './actions';

import './styles.scss';

const PasswordSetup = () => {
    const isForgotPassword = getUrlParam('isForgotPassword');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (password.length >= 8 && password === confirmPassword && (username || isForgotPassword)) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [password, confirmPassword, username, isForgotPassword]);

    const submitChangePassword = () => {
        analytics.fireGeneralEvent({
            action: 'Setting Up Username and Password',
        });
        dispatch(setPasswordAndUsernameAction(password, username, getUrlParam('confirmationToken')));
    };

    return (
        <div className="change-password">
            <div className="change-password-container">
                <h2 className="change-password-header">Setup Your Password</h2>
                <div className="change-password-form">
                    {!isForgotPassword && (
                        <Input label="Full Name" value={username} onChange={e => setUsername(e.target.value)} />
                    )}
                    <Input
                        type="password"
                        label="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Input
                        type="password"
                        label="Re-enter Password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        disabled={isSubmitDisabled}
                        className="btn-primary change-password-button"
                        onClick={submitChangePassword}
                    >
                        Done
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PasswordSetup;
