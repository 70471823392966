import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRanches } from 'components/views/Ranches/actions';
import Select from '@beewise/select';
import Button from '@beewise/button';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import Grid from 'components/reusables/Grid';
import { fetchWeatherData, aggregateWeatherData, saveWeatherData } from './actions';
import './WeatherData.scss';

const getPotential = (data, ratio, country) => {
    const dumansPerAcre = 4;
    const adjustedRatio = country === 'us' ? ratio : ratio * dumansPerAcre;
    const numCells = data.averageFrameCount
        ? ((data.averageFrameCount * data.flightHours * adjustedRatio) / 128) * 100
        : null;
    return numCells?.toFixed(1);
};

const WeatherData = () => {
    const [api, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const ranches = useSelector(state => state.ranches.ranches);

    const ranchesOptions = useMemo(() => ranches.map(ranch => ({ value: ranch.id, text: ranch.name })), [ranches]);

    const [selectedRanch, setSelectedRanch] = useState(null);

    const { blocks, country } = useMemo(
        () => (selectedRanch ? ranches.find(ranch => ranch.id === selectedRanch) : {}),
        [selectedRanch, ranches]
    );

    const ratio = blocks?.reduce((acc, block, i) => {
        acc += block.ratio;
        return i === blocks.length - 1 ? acc / blocks.length : acc;
    }, 0);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const [weatherData, setWeatherData] = useState(null);

    const handleSelectRanch = useCallback((e, { value }) => setSelectedRanch(value), []);

    const handleGetWeatherData = useCallback(() => {
        dispatch(
            aggregateWeatherData(
                selectedRanch,
                dayjs(from).format('YYYY-MM-DD'),
                dayjs(to).format('YYYY-MM-DD'),
                data => {
                    setWeatherData(data.map(it => ({ ...it, id: it.date })));
                }
            )
        );
    }, [selectedRanch, to, from, dispatch]);

    useEffect(() => {
        dispatch(fetchRanches());
    }, [dispatch]);

    useEffect(() => {
        if (selectedRanch) {
            dispatch(
                fetchWeatherData(selectedRanch, data => {
                    setWeatherData(data.map(it => ({ ...it, id: it.date })));
                })
            );
        }
    }, [selectedRanch, dispatch]);

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'date', field: 'date', editable: false },
                { headerName: 'daily_temperature_average', field: 'daily_temperature_average', editable: false },
                { headerName: 'total_act', field: 'total_act', editable: false },
                { headerName: 'Flight hours', field: 'flightHours', editable: false },
                { headerName: 'temperature F', field: 'temperature', editable: false },
                { headerName: 'Average frame count', field: 'averageFrameCount', editable: true },
                {
                    headerName: 'Pollination potential',
                    field: 'numCells',
                    editable: false,
                    valueGetter: params => getPotential(params.data, ratio, country),
                },
            ],
        }),
        [ratio, country]
    );

    const handleOnGridReady = useCallback(api => setGridApi(api), []);

    const handleSaveWeatherData = useCallback(() => {
        const data = [];
        api.forEachNode(node => {
            const numCells = getPotential(node.data, ratio, country);
            data.push({ ...node.data, numCells });
        });
        dispatch(saveWeatherData(selectedRanch, data));
    }, [api, dispatch, selectedRanch, ratio, country]);

    const exportDataAsCsv = useCallback(() => api.exportDataAsCsv(), [api]);

    return (
        <div className="weather-data">
            <div className="row">
                <Select options={ranchesOptions} onChange={handleSelectRanch} />
                <DatePicker
                    selected={from}
                    onChange={setFrom}
                    popperPlacement="top-start"
                    placeholderText="Start date"
                />
                <DatePicker selected={to} onChange={setTo} popperPlacement="top-start" placeholderText="End date" />
                <Button className="btn-weather" onClick={handleGetWeatherData}>
                    Get Weather Data
                </Button>
                <Button className="btn-weather" disabled={!weatherData?.length} onClick={handleSaveWeatherData}>
                    Save generated report
                </Button>
                <Button className="btn-weather" disabled={!weatherData?.length} onClick={exportDataAsCsv}>
                    Export to CSV
                </Button>
            </div>
            <div id="view" className="page">
                {weatherData && (
                    <Grid onGridReady={handleOnGridReady} rowData={weatherData} gridOptions={gridOptions} />
                )}
            </div>
        </div>
    );
};

export default WeatherData;
