import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from '@beewise/select';
import { get, isEqual } from 'lodash-es';
import { loading, useToggle } from '@beewise/react-utils';
import { fetchBhomeSettings, fetchSaveSettings, setCurrentBhome } from '../../actions';
import BhomeSettings from './BhomeSettings';
import { FETCH_BHOME_SETTINGS } from '../../actionTypes';

const BhomeConfig = ({ bhomes, isSuperViewer }) => {
    const dispatch = useDispatch();

    // added to hack JSONEditor inner rendering procedure
    const [fooKey, setFooKey] = useState(1);
    const [isFetched, setIsFetched] = useState(false);
    const [disableHashCheck, toggleHashCheck] = useToggle();
    const [enforceImmediateAction, toggleEnforceImmediateAction] = useToggle();
    const [isTextMode, toggleMode] = useToggle();
    const [originalSettings, setOriginalSettings] = useState(null);
    const [currentSettings, setCurrentSettings] = useState(null);
    const selectOptions = useMemo(() => bhomes.map(item => ({ text: item.id, value: item.id })), [bhomes]);
    const currentBhomeId = useSelector(state => state.dashboard.currentBhomeId);
    const currentBhomeSettings = useSelector(state => state.dashboard.currentBhomeSettings, shallowEqual);

    useEffect(() => {
        if (currentBhomeId) {
            dispatch(
                fetchBhomeSettings(currentBhomeId, () => {
                    setIsFetched(true);
                    setTimeout(() => setFooKey(fooKey + 1), 0);
                })
            );
        }
        // eslint-disable-next-line
    }, [currentBhomeId, dispatch, setFooKey]);

    useEffect(() => {
        setOriginalSettings(currentBhomeSettings);
        setCurrentSettings(currentBhomeSettings);
        setFooKey(fooKey + 1);
        // eslint-disable-next-line
    }, [currentBhomeSettings]);

    useEffect(() => () => dispatch(setCurrentBhome(null)), [dispatch]);

    const handleBhomeSelect = useCallback(
        (e, { value }) => {
            dispatch(setCurrentBhome(value));
        },
        [dispatch]
    );

    const handleToggleMode = useCallback(() => {
        setFooKey(fooKey + 1);
        toggleMode();
    }, [fooKey, toggleMode]);

    const handleSettingsChange = useCallback(
        settings =>
            setCurrentSettings({
                ...currentSettings,
                data: {
                    ...currentSettings.data,
                    ...settings,
                },
            }),
        [currentSettings]
    );

    const handleDownload = useCallback(() => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(currentSettings.data.settings)
        )}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', `bhome_${currentBhomeId}_config.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [currentSettings, currentBhomeId]);

    const handleSaveSettings = useCallback(() => {
        dispatch(
            fetchSaveSettings({
                id: currentBhomeId,
                settings: {
                    data: currentSettings.data,
                    comment: currentSettings.comment,
                },
                previousSettingsData: get(originalSettings, 'data.settings', null),
                disableHashCheck,
                enforceImmediateAction,
                resolver: () => {
                    setFooKey(fooKey + 1);
                },
            })
        );
    }, [dispatch, currentBhomeId, currentSettings, originalSettings, disableHashCheck, enforceImmediateAction, fooKey]);

    const isSubmitDisabled = useMemo(
        () => (isEqual(currentSettings, originalSettings) && !disableHashCheck) || isSuperViewer,
        [currentSettings, originalSettings, disableHashCheck, isSuperViewer]
    );

    return (
        <div className="config">
            <Select options={selectOptions} onChange={handleBhomeSelect} search />
            {currentBhomeId && !currentSettings && isFetched && (
                <div className="config-no-settings">Selected Bhome doesn&#39;t have settings.</div>
            )}
            {currentBhomeId && currentSettings && currentSettings.data && currentSettings.data.settings && (
                <BhomeSettings
                    currentSettings={currentSettings}
                    fooKey={fooKey}
                    handleSaveSettings={handleSaveSettings}
                    handleSettingsChange={handleSettingsChange}
                    isSubmitDisabled={isSubmitDisabled}
                    disableHashCheck={disableHashCheck}
                    toggleHashCheck={toggleHashCheck}
                    isTextMode={isTextMode}
                    toggleMode={handleToggleMode}
                    handleDownload={handleDownload}
                    enforceImmediateAction={enforceImmediateAction}
                    toggleEnforceImmediateAction={toggleEnforceImmediateAction}
                />
            )}
        </div>
    );
};

BhomeConfig.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    isSuperViewer: PropTypes.bool,
};

export default loading([FETCH_BHOME_SETTINGS.default])(BhomeConfig);
