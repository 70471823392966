import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading } from '@beewise/react-utils';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import { fetchUsers } from 'components/views/Users/actions';
import { clearViewData } from 'actions';
import { fetchPermissions, fetchUpdatePermission } from 'components/views/UserPermissions/actions';
import { FETCH_UPDATE_USER_PERMISSION, FETCH_USER_PERMISSIONS } from 'components/views/UserPermissions/actionTypes';
import Select from '@beewise/select';
import Button from '@beewise/button';

import './UserPermissions.scss';

const UserPermissionsTab = () => {
    const dispatch = useDispatch();
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPermissions, setCurrentPermissions] = useState(null);

    const permissions = useSelector(state => state.userPermissions.permissions, arrayOfObjectsShallowEqual);
    const users = useSelector(state => state.users.users, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchPermissions());
        dispatch(fetchUsers());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const usersOptions = useMemo(
        () =>
            users.map(item => ({
                text: item.email,
                value: item.id,
            })),
        [users]
    );

    const handleSetUser = useCallback(
        (e, { value }) => {
            setSelectedUser(value);
            setCurrentPermissions(permissions.find(item => item.user_id === value));
        },
        [permissions]
    );

    const handleSetCurrentPermissions = useCallback(
        permissions => {
            setCurrentPermissions({
                ...currentPermissions,
                data: permissions,
            });
        },
        [currentPermissions]
    );

    const handleSavePermissions = useCallback(() => {
        if (currentPermissions?.data) {
            dispatch(
                fetchUpdatePermission(currentPermissions?.id, {
                    data: currentPermissions.data,
                })
            );
        }
    }, [currentPermissions, dispatch]);

    return (
        <div>
            <Select className="kebab" search options={usersOptions} onChange={handleSetUser} />
            {currentPermissions && (
                <div className="permissions-editor" key={`${selectedUser}-editor`}>
                    <Editor
                        mode="tree"
                        value={currentPermissions?.data}
                        onChange={permissions => handleSetCurrentPermissions(permissions)}
                    />
                    <Button shadow className="primary-blue" onClick={handleSavePermissions}>
                        Save
                    </Button>
                </div>
            )}
        </div>
    );
};

export default loading([FETCH_USER_PERMISSIONS.default, FETCH_UPDATE_USER_PERMISSION.default])(UserPermissionsTab);
