import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useToggle } from '@beewise/react-utils';
import Button from '@beewise/button';
import isSignInViewOrUpView from 'utils/isSignInOrUpView';
import { ProfileModal } from './components';
import logo from './beewise-logo.svg';

import './AppHeader.scss';

const AppHeader = () => {
    const [isProfileModalOpen, toggleProfileModal] = useToggle(false);
    const location = useLocation();
    const isSignInView = isSignInViewOrUpView(location);

    return (
        <header className="app-header">
            <div className="logo-container">
                <NavLink to="/">
                    <img className="logo" src={logo} alt="Beewise logo" />
                </NavLink>
                {!isSignInView && (
                    <div className="nav-list">
                        <NavLink to="/users">Users</NavLink>
                        <NavLink to="/user-types">User Types</NavLink>
                        <NavLink to="/user-roles">User Roles</NavLink>
                        <NavLink to="/user-permissions">User Permissions</NavLink>
                        <NavLink to="/user-bhomes">User Bhomes</NavLink>
                        <NavLink to="/companies">Companies</NavLink>
                        <NavLink to="/company-type">Company Type</NavLink>
                        <NavLink to="/companies-types">Companies Types</NavLink>
                        <NavLink to="/company-bhomes">Company Bhomes</NavLink>
                        <NavLink to="/company-users">Company Users</NavLink>
                        <NavLink to="/bhomes">Bhomes</NavLink>
                        <NavLink to="/bhome-models">Bhome models</NavLink>
                        <NavLink to="/software-bundles">Software bundles</NavLink>
                        <NavLink to="/assemblies">Assemblies</NavLink>
                        <NavLink to="/bhome-assemblies">Bhome assemblies</NavLink>
                        <NavLink to="/locations">Locations</NavLink>
                        <NavLink to="/location-types">Location types</NavLink>
                        <NavLink to="/ranches">Ranches</NavLink>
                        <NavLink to="/settings">Settings</NavLink>
                        <NavLink to="/ranch-locations">Ranch locations</NavLink>
                        <NavLink to="/ranch-milestones">Ranch milestones</NavLink>
                        <NavLink to="/conference">Conference</NavLink>
                        <NavLink to="/weather-data">Weather data</NavLink>
                        <NavLink to="/incubators">Incubators</NavLink>
                    </div>
                )}
            </div>
            {!isSignInView && (
                <>
                    <div className="main-menu">
                        <Button
                            className="icon"
                            iconType="user"
                            iconClassName="user-white"
                            onClick={toggleProfileModal}
                        />
                    </div>
                    <ProfileModal isOpen={isProfileModalOpen} toggleModal={toggleProfileModal} />
                </>
            )}
        </header>
    );
};

export default AppHeader;
