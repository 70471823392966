import constants from '@beewise/constants';
import { getContractHiveActionWithLayout } from './contractHive';
import { getExpandHiveActionWithLayout } from './expandHive';

const isArrayNonEmpty = (arr) => Array.isArray(arr) && arr.length;
const prepareFrames = ({ id, place, status, frameId }) => ({
  id,
  place,
  status,
  frameId
});
const prepareMovedFrames = ({ id, place, type, status, rfid, frameId, initialFrameIndex }) => ({
  id,
  place,
  type,
  rfid,
  status,
  frameId,
  initialFrameIndex
});
const isContractPlanner = ({ currentPartitionIndex, movedFrames }) =>
  currentPartitionIndex > 0 &&
  movedFrames
    .slice(0, currentPartitionIndex)
    .some((frame, index) => frame.initialFrameIndex !== index);
const isExpandPlanner = ({ currentPartitionIndex, initianlPartitionIndex, movedFrames }) =>
  movedFrames.some(
    (frame, index) =>
      frame.initialFrameIndex !== index &&
      frame.initialFrameIndex < initianlPartitionIndex &&
      index > currentPartitionIndex
  );

/**
 * Checks if the moved frames data is valid.
 * @param {Object} param0 - Object containing movedFrames.
 * @param {Array} param0.frames - Array of whole bhome frames before movement.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of moved frames.
 * @returns {boolean} True if frames, selectedFrames, movedFrames are arrays and have elements,
 *                    and if selectedFrames and movedFrames have same length, false otherwise.
 */
const isFramesDataValid = ({ frames, selectedFrames, movedFrames }) =>
  isArrayNonEmpty(frames) &&
  isArrayNonEmpty(selectedFrames) &&
  isArrayNonEmpty(movedFrames) &&
  selectedFrames.length === movedFrames.length;

/**
 * Handles the movement of frames based on provided data.
 * @param {Object} param0 - Object containing movedFrames, stations, and settings.
 * @param {Array} param0.frames - Array of whole bhome frames before movement.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of frames that have been moved within param0.selectedFrames.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @returns {Object} An object representing the hive action sequences and new frames layout, or throws an error if data is invalid.
 */
const moveFramesCalculatorV4 = ({ frames, selectedFrames, movedFrames, stations, settings }) => {
  if (!isFramesDataValid({ frames, selectedFrames, movedFrames })) {
    throw new Error('Provided frames data is not valid');
  }

  const preparedSelectedFrames = structuredClone(selectedFrames);

  const preparedMovedFrames = movedFrames.map(prepareMovedFrames);

  const preparedFrames = frames.map(prepareFrames);

  const currentPartitionIndex = preparedMovedFrames.findIndex(
    ({ type }) => type === constants.FRAME_TYPES.PARTITION
  );
  const initianlPartitionIndex = preparedSelectedFrames.findIndex(
    ({ type }) => type === constants.FRAME_TYPES.PARTITION
  );

  const movedFramesData = { actions: [], newHiveLayout: preparedMovedFrames };

  if (isContractPlanner({ currentPartitionIndex, movedFrames: preparedMovedFrames })) {
    const { contractHiveAction, newHiveLayout } = getContractHiveActionWithLayout({
      movedFrames: movedFramesData.newHiveLayout,
      settings,
      stations,
      currentPartitionIndex
    });

    movedFramesData.actions.push({ contractHiveAction });
    movedFramesData.newHiveLayout = newHiveLayout;
  }
  if (
    isExpandPlanner({
      currentPartitionIndex,
      initianlPartitionIndex,
      movedFrames: preparedMovedFrames
    })
  ) {
    const { expamdHiveAction, newHiveLayout } = getExpandHiveActionWithLayout({
      movedFrames: movedFramesData.newHiveLayout,
      settings,
      stations,
      currentPartitionIndex
    });

    movedFramesData.actions.push({ expamdHiveAction });
    movedFramesData.newHiveLayout = newHiveLayout;
  }

  const { actions, newHiveLayout } = movedFramesData;

  const changedHiveStartIndex = preparedFrames.findIndex(
    (frame) => frame.id === preparedSelectedFrames[0].id
  );

  preparedFrames.splice(
    changedHiveStartIndex,
    preparedSelectedFrames.length,
    ...newHiveLayout.map(prepareFrames)
  );

  return {
    actions,
    newFramesLayout: preparedFrames.map((frame, index) => ({ ...frame, id: index }))
  };
};

export default moveFramesCalculatorV4;
