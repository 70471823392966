import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading, useToggle } from '@beewise/react-utils';
import Select from '@beewise/select';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import Button from '@beewise/button';
import { get } from 'lodash-es';
import Checkbox from '@beewise/checkbox-field';
import { fetchBhomeSettings, setCurrentBhome, fetchBhomeSettingsHistory, fetchSaveSettings } from '../../actions';
import { FETCH_BHOME_SETTINGS_HISTORY, FETCH_BHOME_SETTINGS } from '../../actionTypes';

const BhomeRevertConfig = ({ bhomes, isSuperViewer }) => {
    const dispatch = useDispatch();
    const [enforceImmediateAction, toggleEnforceImmediateAction] = useToggle();
    const [historyItem, setHistoryItem] = useState(null);

    const selectOptions = useMemo(() => bhomes.map(item => ({ text: item.id, value: item.id })), [bhomes]);
    const currentBhomeId = useSelector(state => state.dashboard.currentBhomeId);
    const currentBhomeSettings = useSelector(state => state.dashboard.currentBhomeSettings, shallowEqual);
    const currentBhomeSettingsHistory = useSelector(
        state => state.dashboard.currentBhomeSettingsHistory,
        arrayOfObjectsShallowEqual
    );
    const historyOptions = useMemo(
        () =>
            currentBhomeSettingsHistory.map(item => ({
                text: `${item.created_at} ${item.user_email}`,
                value: item.id,
            })),
        [currentBhomeSettingsHistory]
    );

    useEffect(() => {
        if (currentBhomeId) {
            dispatch(fetchBhomeSettings(currentBhomeId));
            dispatch(fetchBhomeSettingsHistory(currentBhomeId));
        }
    }, [currentBhomeId, dispatch]);

    useEffect(() => {
        dispatch(setCurrentBhome(null));
        return () => dispatch(setCurrentBhome(null));
    }, [dispatch]);

    const handleBhomeSelect = useCallback(
        (e, { value }) => {
            dispatch(setCurrentBhome(value));
            setHistoryItem(null);
        },
        [dispatch]
    );

    const handleHistorySelect = useCallback(
        (e, { value }) => {
            setHistoryItem(currentBhomeSettingsHistory.find(item => item.id === value));
        },
        [currentBhomeSettingsHistory]
    );

    const handleApplyClick = useCallback(() => {
        /* eslint-disable */
        if (confirm('Are you sure you want to apply history config?')) {
            dispatch(
                fetchSaveSettings({
                    id: currentBhomeId,
                    settings: {
                        data: historyItem.data,
                        comment: '',
                    },
                    previousSettingsData: get(currentBhomeSettings, 'data.settings', null),
                    disableHashCheck: false,
                    enforceImmediateAction,
                })
            );
        }
        /* eslint-enable */
    }, [currentBhomeId, currentBhomeSettings, dispatch, enforceImmediateAction, historyItem?.data]);

    return (
        <div className="revert-config">
            <Select options={selectOptions} onChange={handleBhomeSelect} search placeholder="Select Bhome" />
            {currentBhomeId && (
                <Select
                    options={historyOptions}
                    onChange={handleHistorySelect}
                    search
                    placeholder="Select History Config"
                />
            )}
            {currentBhomeId && historyItem && !isSuperViewer && (
                <>
                    <Checkbox
                        className="config-hash-checkbox"
                        onChange={toggleEnforceImmediateAction}
                        checked={enforceImmediateAction}
                        label="Enforce Immediate"
                    />
                    <Button className="revert-config-apply btn-primary" onClick={handleApplyClick}>
                        Apply History Config
                    </Button>
                </>
            )}
            {historyItem && currentBhomeSettings && (
                <div className="config-content">
                    <ReactDiffViewer
                        leftTitle="Old Config"
                        rightTitle="Current Config"
                        compareMethod={DiffMethod.LINES}
                        splitView
                        oldValue={JSON.stringify(historyItem?.data?.settings, null, 4)}
                        newValue={JSON.stringify(currentBhomeSettings?.data?.settings, null, 4)}
                    />
                </div>
            )}
        </div>
    );
};

BhomeRevertConfig.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    isSuperViewer: PropTypes.bool,
};

export default loading([FETCH_BHOME_SETTINGS_HISTORY.default, FETCH_BHOME_SETTINGS.default])(BhomeRevertConfig);
