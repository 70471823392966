import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Input from '@beewise/input';
import { isEqual } from 'lodash-es';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import Button from '@beewise/button';
import Checkbox from '@beewise/checkbox-field';

const BhomeSettings = ({
    currentSettings,
    handleSettingsChange,
    fooKey,
    handleSaveSettings,
    isSubmitDisabled,
    toggleHashCheck,
    disableHashCheck,
    isTextMode,
    toggleMode,
    handleDownload,
    enforceImmediateAction,
    toggleEnforceImmediateAction,
}) => {
    const areSettingsSame = useMemo(
        () => isEqual(currentSettings.data.settings, currentSettings.machine_data.settings),
        [currentSettings]
    );
    const oldValue = useMemo(() => JSON.stringify(currentSettings.machine_data.settings, null, 4), [currentSettings]);
    const newValue = useMemo(() => JSON.stringify(currentSettings.data.settings, null, 4), [currentSettings]);

    return (
        <>
            <div className="config-content">
                <Input label="Bhome ID" value={currentSettings.bhome_id} disabled />
                <Input
                    className="config-comment"
                    label="Comment"
                    value={currentSettings.comment}
                    onChange={e => handleSettingsChange({ comment: e.target.value })}
                />
                <div key={`${fooKey}-editor`}>
                    <div className="config-header">
                        <Checkbox
                            className="config-mode-switch"
                            label="Text Mode"
                            checked={isTextMode}
                            onChange={toggleMode}
                        />
                        <Button className="btn btn-primary" onClick={handleDownload}>
                            Download Config
                        </Button>
                        <div className="config-updater">
                            Last updated:{' '}
                            {currentSettings?.last_updated
                                ? new Date(currentSettings.last_updated).toLocaleString()
                                : 'never'}{' '}
                            (by: {currentSettings.user_email})
                        </div>
                    </div>
                    <Editor
                        mode={isTextMode ? 'text' : 'tree'}
                        value={currentSettings.data.settings}
                        onChange={settings => handleSettingsChange({ settings })}
                    />
                </div>
                <Checkbox
                    className="config-hash-checkbox"
                    onChange={toggleHashCheck}
                    checked={disableHashCheck}
                    label="Disable Hash Check"
                />
                <Checkbox
                    className="config-hash-checkbox"
                    onChange={toggleEnforceImmediateAction}
                    checked={enforceImmediateAction}
                    label="Enforce Immediate"
                />
                <Button disabled={isSubmitDisabled} primary className="btn btn-primary" onClick={handleSaveSettings}>
                    Save
                </Button>
            </div>
            {!areSettingsSame && (
                <div className="config-content">
                    <ReactDiffViewer
                        leftTitle="Machine Config"
                        rightTitle="Cloud config"
                        compareMethod={DiffMethod.LINES}
                        splitView
                        oldValue={oldValue}
                        newValue={newValue}
                    />
                </div>
            )}
        </>
    );
};

BhomeSettings.propTypes = {
    currentSettings: PropTypes.shape(),
    handleSettingsChange: PropTypes.func,
    toggleHashCheck: PropTypes.func,
    toggleEnforceImmediateAction: PropTypes.func,
    handleSaveSettings: PropTypes.func,
    toggleMode: PropTypes.func,
    handleDownload: PropTypes.func,
    fooKey: PropTypes.number,
    isSubmitDisabled: PropTypes.bool,
    disableHashCheck: PropTypes.bool,
    enforceImmediateAction: PropTypes.bool,
    isTextMode: PropTypes.bool,
};

export default BhomeSettings;
